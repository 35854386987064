/* Grundlegendes Layout */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f0f2f5;
  color: #333;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow-x: hidden; /* Verhindert horizontales Scrollen */
}

/* Haupt-Container der App */
.app-container {
  width: 100%;
  max-width: 600px;
  margin: 20px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
}

/* App-Titel */
h1 {
  font-size: 2.2em;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}

/* Textbereich */
.text-input {
  width: 100%;
  max-width: 100%;
  height: 120px;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #f7f9fc;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  outline: none;
  resize: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  box-sizing: border-box;
  word-wrap: break-word; /* Lange Wörter umbrechen */
  overflow-y: auto; /* Vertikales Scrollen bei langem Text */
}

.text-input:focus {
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
  transform: scale(1.02);
}

/* Standard Button */
.button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.15);
}

.button:hover {
  background-color: #0069d9;
  transform: translateY(-2px);
}

/* Container für Textausgabe und Zusammenfassung */
.output-container {
  margin-top: 30px;
  text-align: left;
}

.output-container h2,
.output-container h3 {
  font-size: 1.4em;
  color: #333;
  font-weight: 500;
  margin: 15px 0 10px;
}

/* Box für den Textinhalt */
.output-box {
  padding: 15px;
  border: 1px solid #e0e0e5;
  border-radius: 8px;
  background-color: #f9fafb;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  white-space: pre-line;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
}

/* Box für die Zusammenfassung */
.summary-box {
  padding: 15px;
  background-color: #e6f4ff;
  border-radius: 8px;
  color: #0056b3;
  font-size: 16px;
  line-height: 1.5;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
}

/* Link-Stil für Fremdwörter */
a.foreign-word-link {
  color: #007bff;
  text-decoration: none;
  border-bottom: 1px dotted #007bff;
  transition: color 0.3s ease;
}

a.foreign-word-link:hover {
  color: #0056b3;
}

/* PDF-Button */
.pdf-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 14px;
  color: #333;
  background-color: #f0f0f3;
  border: 1px solid #d1d1d6;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pdf-button:hover {
  background-color: #e2e3e5;
}

/* Button-Container */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.clear-button, .pdf-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.15);
  width: 48%;
  text-align: center;
}

.clear-button:hover, .pdf-button:hover {
  background-color: #0069d9;
  transform: translateY(-2px);
}

/* Kamera-Buttons */
.toggle-camera-button,
.activate-camera-button {
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  margin: 5px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 123, 255, 0.15);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.toggle-camera-button:hover,
.activate-camera-button:hover {
  background-color: #0069d9;
  transform: translateY(-2px);
}

/* OCR-Container */
.ocr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  max-height: 400px; /* Begrenzt die maximale Höhe */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 20px;
  background-color: #f9fafb;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  overflow-y: auto; /* Ermöglicht Scrollen bei Überlauf */
}

/* Webcam Feed */
.webcam {
  width: 100%;
  max-width: 100%;
  max-height: 250px; /* Verhindert zu große Höhe des Webcam-Bilds */
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.capture-button {
  margin-top: 10px;
  padding: 8px 16px;
  font-size: 14px;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.15);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.capture-button:hover {
  background-color: #0069d9;
  transform: translateY(-2px);
}

/* Responsives Design */
@media (max-width: 768px) {
  .app-container {
    padding: 15px;
    margin: 0 10px; /* Reduzierte Margen für kleinere Bildschirme */
    width: 100%;
  }

  h1 {
    font-size: 1.8em;
  }

  .text-input {
    height: 100px;
    padding: 10px;
  }

  .button {
    font-size: 15px;
    padding: 10px;
  }

  .ocr-container {
    padding: 10px;
    margin-top: 15px;
    max-height: 300px; /* Reduzierte Höhe für kleinere Bildschirme */
  }

  .webcam {
    max-height: 200px; /* Reduzierte Webcam-Höhe auf kleinen Bildschirmen */
  }
}

/* Media Query für noch kleinere Bildschirme (iPhone-Größen) */
@media (max-width: 480px) {
  .app-container {
    padding: 10px;
    margin: 0 5px;
    width: 100%;
  }

  h1 {
    font-size: 1.6em;
  }

  .text-input {
    height: 80px;
    padding: 8px;
    font-size: 14px;
  }

  .button {
    font-size: 14px;
    padding: 8px;
  }

  .ocr-container {
    padding: 8px;
    max-height: 250px; /* Weitere Reduzierung der OCR-Container-Höhe */
  }

  .webcam {
    max-height: 180px; /* Anpassung der Webcam-Größe für noch kleinere Bildschirme */
  }

  .output-box, .summary-box {
    font-size: 14px; /* Kleinere Schrift für Textboxen */
  }
}

/* Webcam Feed */
.webcam-feed {
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 200px; /* Begrenzt die maximale Höhe des Webcam-Bilds */
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Datei-Upload-Container */
.file-upload-container {
  margin-top: 20px;
  text-align: center;
}

.file-upload-button {
  padding: 10px 20px;
  font-size: 14px;
  color: #333;
  background-color: #f0f0f3;
  border: 1px solid #d1d1d6;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-upload-button:hover {
  background-color: #e2e3e5;
}

/* Versteckt den eigentlichen Datei-Upload-Input */
.file-upload-input {
  display: none;
}

/* Benutzerdefinierter Button-Stil für den Datei-Upload */
.custom-upload-button {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.15);
  display: inline-block;
}

.custom-upload-button:hover {
  background-color: #0069d9;
  transform: translateY(-2px);
}